/**
 * Takes a given URL and returns its path as an array of path parts
 * @param {URL} url
 */
const getPathParts = ({ pathname = '' }) => pathname.slice(1).split('/');

/**
 * Given 2 URLs, returns the common parent path of both - or root ('/') if no matching parent path
 * @param {URL} url1
 * @param {URL} url2
 */
const getCommonParentPath = (url1, url2) => {
    const rootPath = '/';

    if (!url1 || !url2) return rootPath;

    const path1Parts = getPathParts(url1);
    const path2Parts = getPathParts(url2);

    if (!path1Parts.length || !path2Parts.length) return rootPath;

    return path1Parts.slice().reduce((contextPath, part, i, parts) => {
        if (path2Parts[i] !== part) {
            // We've hit a part in the paths that does not match. Break out of the reduce and return the matching portion of the paths.
            parts.splice(1);
            return contextPath;
        }
        return `${contextPath}/${part}`;
    }, '');
};

const getCurrentScriptURL = () => {
    const currentScriptSrc = document.currentScript?.src;
    if (!currentScriptSrc) return window.location.origin;
    return new URL(currentScriptSrc);
};

// Compare the page URL with that of the currently running script to find the common root context.
const appContext = getCommonParentPath(window.location, getCurrentScriptURL());

export default appContext;
